import React, { useState, useEffect, useContext } from 'react';
import { Header, Footer } from './';
import { useNavigate } from 'react-router-dom';
import { Formik, Field } from 'formik';
import { DashboardMenu, NavigationBar } from '../components';
import { SiteContext } from '../context';
import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
    cat: Yup.string()
        .label('Category')
        .required(),
    title: Yup.string()
        .label('Title')
        .required(),
    content: Yup.string()
        .label('Content')
        .required()
});

const PhotoAnimationAdd = () => {

    let navigate = useNavigate();

    const { apiUrl, slugify } = useContext(SiteContext);

    const userData = JSON.parse(localStorage.getItem('user'));

    const [uploadedPic, setUploadedPic] = useState(null);
    const [msg, setMsg] = useState('');
    const [errorMsg, setErrorMsg] = useState('');


    const handleSubmit = (values, { setSubmitting, resetForm }) => {

        let formData = new FormData();

        formData.append('cat', values.cat);
        formData.append('title', values.title);
        formData.append('content', values.content);
        formData.append('link', values.link);
        formData.append('photo', values.photo);
        formData.append('user', userData.username);
        formData.append('role', userData.role);
        formData.append('action', 'create');

        return fetch(`${apiUrl}/api/homeanimations.php`, {
            method: 'post',
            body: formData
        })
            .then((res) => res.json())
            .then(async (res) => {

                alert(JSON.stringify(res));

                console.log(res);

                if (res.status === 200) {
                    resetForm({});
                    setSubmitting(false);
                    setMsg(res.msg);
                    setTimeout(() => {
                        setMsg('');
                    }, 3000);
                    setTimeout(() => {
                        navigate('/adminphotoanimation');
                    }, 3000);
                } else if (res.status === 400) {
                    setErrorMsg(res.msg);
                    setTimeout(() => {
                        setErrorMsg('');
                    }, 5000);
                    setSubmitting(false);
                } else {
                    alert(
                        'Photo submission failed',
                        'Error connection to the server please check your details and try again',
                        [{ text: 'Okay' }]
                    );
                }
            })
            .catch((error) => {
                console.log('Api call error', error.message);
            });
    };



    return (
        <div>
            <Header />
            <div>
                <div className='row'>
                    <div className='col-md-2 bg-light'>
                        <DashboardMenu />
                    </div>
                    <div className='col-md-10'>
                        <div className='container my-2'>

                            <NavigationBar section={'Photo Animation'} goback={true} />

                            <h3>Photo Animation Manage</h3>
                            <div className='my-2 d-flex justify-content-between'>
                                <div className='align-items-center'>
                                    {msg !== '' ? <div className='p-2'><span className='p-2 border border-info text-dark'>{msg}</span></div> : null}
                                    {errorMsg !== '' ? <div className='p-2'><span className='p-2 border border-info text-dark'>{errorMsg}</span></div> : null}
                                </div>
                            </div>

                            <Formik
                                initialValues={{
                                    cat: '',
                                    title: '',
                                    content: '',
                                    username: userData.username,
                                }}
                                validationSchema={validationSchema}
                                onSubmit={(values, { setSubmitting, resetForm }) => {
                                    handleSubmit(values, { setSubmitting, resetForm });
                                }}
                            >
                                {({
                                    handleChange,
                                    values,
                                    handleSubmit,
                                    errors,
                                    isValid,
                                    isSubmitting,
                                    handleBlur,
                                    touched,
                                    setFieldValue
                                }) => (
                                    <div>
                                        <form onSubmit={handleSubmit} encype="multipart/form-data">
                                            <div className='container my-2'>

                                                <div className='form-group my-1'>
                                                    <div>
                                                        <label htmlFor="cat">Category</label>
                                                    </div>
                                                    <Field
                                                        as="select"
                                                        id="cat"
                                                        name="cat"
                                                        value={values.cat}
                                                        placeholder="Category"
                                                        onBlur={handleBlur('cat')}
                                                        autoCapitalize="none"
                                                        style={{
                                                            padding: 4
                                                        }}
                                                        className="form-control"
                                                    >
                                                        <option value="">Select...</option>
                                                        <option value="Shop">Shop</option>
                                                        <option value="Home">Home page</option>

                                                    </Field>

                                                    {touched.cat && errors.cat && <div className='py-1'>
                                                        <span className='text-danger'>{touched.cat && errors.cat}</span>
                                                    </div>}
                                                </div>

                                                <div className='form-group my-3'>
                                                    <div>
                                                        <label htmlFor="photo"> Upload picture</label>
                                                    </div>
                                                    <input id="photo" name="photo" type="file" onChange={(event) => {
                                                        setFieldValue("photo", event.currentTarget.files[0]);
                                                        setUploadedPic(event.currentTarget.files[0]);
                                                    }} />
                                                    {uploadedPic && <img className='my-2' src={URL.createObjectURL(uploadedPic)} class="img-fluid mt-2" width={200} height={100} id="output_image" />}
                                                </div>

                                                <div className='form-group my-1'>
                                                    <div>
                                                        <label htmlFor="title">Picture caption</label>
                                                    </div>
                                                    <Field
                                                        id="title"
                                                        name="title"
                                                        value={values.title}
                                                        onChangeText={handleChange('title')}
                                                        placeholder="Picture caption"
                                                        placeholderTextColor="#999"
                                                        onBlur={handleBlur('title')}
                                                        autoCapitalize="none"
                                                        style={{
                                                            padding: 4
                                                        }}
                                                        className="form-control"
                                                    />
                                                    {touched.title && errors.title && <div className='py-1'>
                                                        <span className='text-danger'>{touched.title && errors.title}</span>
                                                    </div>}
                                                </div>

                                                <div className='form-group my-1'>
                                                    <div>
                                                        <label htmlFor="content">Content</label>
                                                    </div>
                                                    <Field
                                                        id="content"
                                                        name="content"
                                                        value={values.content}
                                                        onChangeText={handleChange('content')}
                                                        placeholder="Picture caption"
                                                        placeholderTextColor="#999"
                                                        onBlur={handleBlur('content')}
                                                        autoCapitalize="none"
                                                        style={{
                                                            padding: 4
                                                        }}
                                                        className="form-control"
                                                    />
                                                    {touched.content && errors.content && <div className='py-1'>
                                                        <span className='text-danger'>{touched.content && errors.content}</span>
                                                    </div>}
                                                </div>


                                                <div className='form-group my-1'>
                                                    <div>
                                                        <label htmlFor="link">Link</label>
                                                    </div>
                                                    <Field
                                                        id="link"
                                                        name="link"
                                                        value={values.link}
                                                        onChangeText={handleChange('link')}
                                                        placeholder="Link"
                                                        placeholderTextColor="#999"
                                                        onBlur={handleBlur('link')}
                                                        autoCapitalize="none"
                                                        style={{
                                                            padding: 4
                                                        }}
                                                        className="form-control"
                                                    />
                                                    {touched.link && errors.link && <div className='py-1'>
                                                        <span className='text-danger'>{touched.link && errors.link}</span>
                                                    </div>}
                                                </div>

                                            </div>
                                            <button
                                                type='submit'
                                                onClick={handleSubmit}
                                                title="Submit"
                                                disabled={!isValid || isSubmitting}
                                                className='btn btn-primary'
                                            >
                                                Submit
                                            </button>

                                            <button
                                                onClick={() => navigate(-1)}
                                                title="Cancel"
                                                className='btn btn-secondary mx-2'
                                            >
                                                Cancel
                                            </button>

                                        </form>
                                    </div>
                                )}
                            </Formik>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default PhotoAnimationAdd;