import React, { useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
    photo: Yup.string()
      .required()
  });
  
const UpdateProfilePictureForm = ({ handleSubmit, setShowModal, user }) => {

  const [errorMsg, setErrorMsg] = useState('');

  const userData = JSON.parse(localStorage.getItem('user'));

  return (
      <div>
        <div className='container'>

          {errorMsg !== '' ? <span className='p-2 my-2 border border-danger text-danger'>{errorMsg}</span> : null}

          <Formik
                    initialValues={{
                      id: user.id,
                      photo: '',
                      posterrole: userData.role,
                      posterid: userData.id
                    }}
                    validationSchema={validationSchema}
                    onSubmit={(values, { setSubmitting, resetForm }) => {
                      handleSubmit(values, { setSubmitting, resetForm, setShowModal });
                    }}
                  >
                    {({
                      handleChange,
                      values,
                      handleSubmit,
                      errors,
                      isValid,
                      isSubmitting,
                      handleBlur,
                      touched,
                      setFieldValue
                    }) => (
                      <form onSubmit={handleSubmit} encype="multipart/form-data">
                        
                            <div className='form-group my-3'>
                                <div>
                                    <label htmlFor="photo"> Upload your picture</label>
                                </div>
                                <input id="photo" name="photo" type="file" onChange={(event) => {
                                        setFieldValue("photo", event.currentTarget.files[0]);
                                }} />
                            </div>

                        <button
                            type='submit'
                            buttonType="outline"
                            onClick={handleSubmit}
                            title="Create Account"
                            disabled={!isValid || isSubmitting}
                            loading={isSubmitting}
                              className='btn btn-primary'
                          >
                              Update
                          </button>
                        
                        
                          </form>
                    )}
                  </Formik>

      </div>
    </div>
    )
}

export default UpdateProfilePictureForm;