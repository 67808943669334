import React, { useState, useContext } from 'react';
import { Header, Footer } from './';
import { DashboardMenu } from '../components';
import { Link, useNavigate } from 'react-router-dom';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import { SiteContext } from '../context';

const validationSchema = Yup.object().shape({
  oldpassword: Yup.string()
    .label('Password')
    .required()
    .min(5, 'Password must have at least 5 characters '),
  password: Yup.string()
    .label('Password')
    .required()
    .min(5, 'Password must have at least 5 characters '),
  password2: Yup.string()
    .oneOf([Yup.ref('password')], 'Confirm Password must match Password')
    .required('Confirm Password is required'),
});

const UpdatePassword = () => {
  const [errorMsg, setErrorMsg] = useState(null);
  const [msg, setMsg] = useState(null);

  const navigate = useNavigate();

  const { apiUrl } = useContext(SiteContext);

  const userData = JSON.parse(localStorage.getItem('user'));

  const handleSubmit = (values, { setSubmitting, resetForm }) => {

    let formData = new FormData();


    formData.append('oldpassword', values.oldpassword);
    formData.append('password', values.password);
    formData.append('user', values.id);
    formData.append('action', 'updatepassword');


    return fetch(`${apiUrl}/api/users.php`, {
      method: 'post',
      body: formData
    })
      .then((res) => {
        return res.json();
      })
      .then((response) => {
        alert(JSON.stringify(response));

        if (response.status === 200) {
          resetForm({});
          setMsg(response.msg);
          setSubmitting(false);
          setTimeout(() => {
            setMsg(null);
          }, 5000);
        } else if (response.status === 400) {
          setErrorMsg(response.msg);
          setTimeout(() => {
            setErrorMsg(null);
          }, 5000);
          setSubmitting(false);
        } else {
          alert(
            'Password update failed',
            'Error connection to the server please check your details and try again',
            [{ text: 'Okay' }]
          );
        }
      })
      .catch((error) => {
        console.log('Api call error', error.message);
      });
  };


  return (
    <div>
      <Header />
      <div>
        <div className='row'>
          <div className='col-md-2 bg-light'>
            <DashboardMenu />
          </div>
          <div className='col-md-10'>
            <div className='container my-2'>
              <h4 className='my-3'>Change password</h4>

              {errorMsg !== null ? <span className='p-2 my-4 border border-danger text-danger'>{errorMsg}</span> : null}
              {msg !== null ? <span className='p-2 my-4 border border-dark text-dark'>{msg}</span> : null}

              <Formik
                initialValues={{
                  id: userData.id,
                  oldpassword: '',
                  password: '',
                  password2: '',
                }}
                validationSchema={validationSchema}
                onSubmit={(values, { setSubmitting, resetForm }) => {
                  handleSubmit(values, { setSubmitting, resetForm });
                }}
              >
                {({
                  handleChange,
                  values,
                  handleSubmit,
                  errors,
                  isValid,
                  isSubmitting,
                  handleBlur,
                  touched,
                  setFieldValue
                }) => (
                  <form onSubmit={handleSubmit} encype="multipart/form-data">
                    <div className='row'>
                      <div className='col-md-6'>

                        <div className='form-group my-1'>
                          <div>
                            <label htmlFor="oldpassword">Current Password</label>
                          </div>
                          <Field
                            id="oldpassword"
                            name="oldpassword"
                            value={values.oldpassword}
                            onChangeText={handleChange('oldpassword')}
                            placeholder="Current Password"
                            placeholderTextColor="#999"
                            onBlur={handleBlur('oldpassword')}
                            secureTextEntry
                            style={{
                              padding: 4
                            }}
                          />

                          {touched.oldpassword && errors.oldpassword && <div className='py-1'>
                            <span className='text-danger'>{touched.oldpassword && errors.oldpassword}</span>
                          </div>}
                        </div>

                        <div className='form-group my-1'>
                          <div>
                            <label htmlFor="password">New Password</label>
                          </div>
                          <Field
                            id="password"
                            name="password"
                            value={values.password}
                            onChangeText={handleChange('password')}
                            placeholder="New Password"
                            placeholderTextColor="#999"
                            onBlur={handleBlur('password')}
                            secureTextEntry
                            style={{
                              padding: 4
                            }}
                          />

                          {touched.password && errors.password && <div className='py-1'>
                            <span className='text-danger'>{touched.password && errors.password}</span>
                          </div>}
                        </div>

                        <div className='form-group my-1'>
                          <div>
                            <label htmlFor="password2">Confirm New Password</label>
                          </div>
                          <Field
                            id="password2"
                            name="password2"
                            value={values.password2}
                            onChangeText={handleChange('password2')}
                            placeholder="Confirm New Password"
                            placeholderTextColor="#999"
                            onBlur={handleBlur('password2')}
                            secureTextEntry
                            style={{
                              padding: 4
                            }}
                          />

                          {touched.password2 && errors.password2 && <div className='py-1'>
                            <span className='text-danger'>{touched.password2 && errors.password2}</span>
                          </div>}
                        </div>

                      </div>
                    </div>
                    <button
                      type='submit'
                      buttonType="outline"
                      onClick={handleSubmit}
                      title="Update"
                      disabled={!isValid || isSubmitting}
                      loading={isSubmitting}
                      className='btn btn-primary my-4'
                    >
                      Update
                    </button>

                    <button
                      onClick={() => navigate(-1)}
                      title="Cancel"
                      className='btn btn-secondary mx-2'
                    >
                      Cancel
                    </button>

                  </form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default UpdatePassword;