import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import {Header, Footer} from './';
import { SiteContext } from "../context/index";

const Account = () => {
    const { userData  } = useContext(SiteContext);

    return (
        <div>
            <Header />
            <div className='container'>
                <h2>Welcome {userData.username} ({userData.role}) to your dashboard</h2>
                <p className='my-2'>{userData.role === 'driver' ? <Link to='/driver'>Driver Dashboard</Link> : <Link to='/admin'>Admin Dashboard</Link>}</p>
                <p className='my-2'><Link to='/profile'>Profile</Link></p>

            </div>
            <Footer />
        </div>
    )
}

export default Account;