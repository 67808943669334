import React, { useState, useEffect, useContext } from "react";
import { Header, Footer, ProjectGallery } from "./";
import { Link, useNavigate, useParams } from "react-router-dom";
import { SiteContext } from "../context";
import {
  ImageDisplay,
  BannerTop,
  BannerSide,
  Comments,
  TwitterFeed,
  TopNews,
} from "../components";
import moment from "moment";
import { formatViews } from "../utils";
import Seo from "./Seo";
import { InlineShareButtons } from "sharethis-reactjs";

const Edition = () => {
  const {
    userData,
    apiUrl,
    siteconfig,
    allNews,
    isLoggedOn,
    categories,
    pages,
    handleCurrentPage,
    isMobile,
  } = useContext(SiteContext);

  const { id } = useParams();

  const navigate = useNavigate();

  const [animationPhotos, setAnimationPhotos] = useState([]);
  const [edition, setEdition] = useState([]);
  const [selecteEdition, setSelectedEdition] = useState({});
  const [news, setNews] = useState({});
  const [storyArr, setStoryArr] = useState([]);
  const [story, setStory] = useState({});
  const [pagelink, setPagelink] = useState("");
  const [filteredOne, setFilteredOne] = useState([]);
  const [filteredFour, setFilteredFour] = useState([]);
  const [homeServices, setHomeServices] = useState([]);
  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  useEffect(() => {
    handleCurrentPage("editions");
    getEdition();
    window.scrollTo(0, 0);
  }, [id]);

  useEffect(() => {
    if (pagelink !== "") {
      let filtered = edition.filter((item) => item.cpageid === pagelink);

      if (filtered.length > 0) {
        setSelectedEdition(filtered[0]);
      }
    }
  }, [pagelink]);

  const getEdition = () => {
    if (id === "" || id === undefined) {
      setErrorMsg("Error retrieving edition");
      return;
    }

    setLoading(true);

    let formData = new FormData();

    formData.append("id", id);
    formData.append("action", "fetch");

    fetch(`${apiUrl}/api/fetch_edition.php`, {
      method: "post",
      body: formData,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.status === 200) {
          let filtered = res.rows.filter(
            (item) => item.cpagename === "About 2023 Edition"
          );
          setEdition(res.rows);
          setSelectedEdition(filtered[0]);
          setLoading(false);
        } else if (res.status === 400) {
          setErrorMsg(res.msg);
          setLoading(false);
          setTimeout(() => {
            setErrorMsg("");
          }, 5000);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const doSanitize = (item) => {
    if (item === "" || item === undefined) {
      return item;
    }
    return item.replace(/<p>/g, "").replace(/<\/p>/g, " ");
  };

  const handleEditionLink = (pid) => {
    setPagelink(pid);
  };

  return (
    <div>
      <Seo
        title={
          selecteEdition.hasOwnProperty("cpageid")
            ? `${selecteEdition.cpagename} | ${siteconfig.shopname}`
            : `${siteconfig.shopname}`
        }
        description={
          selecteEdition.hasOwnProperty("cpageid") &&
          selecteEdition.cpagecontent
        }
        url={`${apiUrl}/api/edition_detail.php?id=${selecteEdition.cpageid}&title=${selecteEdition.cpagelinkname}`}
        img={
          selecteEdition.cpagephoto !== null
            ? `${apiUrl}/${selecteEdition.cpagephoto}`
            : siteconfig.shoplogo
        }
      />
      <Header />
      <div style={{ backgroundColor: "#ff0000" }} className="p-2">
        <div className="container h1 text-light">
          Editions | {id.split("-").join(" ")}
        </div>
      </div>

      {loading && (
        <div className="d-flex justify-content-center align-content-center my-5">
          <div class="spinner-border text-secondary" role="status" />
        </div>
      )}

      {errorMsg !== "" && <div className="container">{errorMsg}</div>}

      <div className="container py-3 my-1">
        <div className="row">
          <div className="container col-md-8 p-2">
            <BannerTop />

            <h1 className="py-3 my-2">{selecteEdition.cpagename}</h1>

            {selecteEdition.hasOwnProperty("cpageid") && (
              <InlineShareButtons
                config={{
                  alignment: "center", // alignment of buttons (left, center, right)
                  color: "social", // set the color of buttons (social, white)
                  enabled: true, // show/hide buttons (true, false)
                  font_size: 16, // font size for the buttons
                  labels: "cta", // button labels (cta, counts, null)
                  language: "en", // which language to use (see LANGUAGES)
                  networks: [
                    // which networks to include (see SHARING NETWORKS)
                    "facebook",
                    "twitter",
                    "email",
                    "linkedin",
                    "whatsapp",
                    "messenger",
                  ],
                  padding: 12, // padding within buttons (INTEGER)
                  radius: 10, // the corner radius on each button (INTEGER)
                  show_total: false,
                  size: 40, // the size of each button (INTEGER)

                  // OPTIONAL PARAMETERS

                  url: `${apiUrl}/api/edition_detail.php?id=${selecteEdition.cpageid}&title=${selecteEdition.cpagelinkname}`,
                  image:
                    selecteEdition.cpagephoto !== null
                      ? `${apiUrl}/${selecteEdition.cpagephoto}`
                      : siteconfig.shoplogo, // (defaults to og:image or twitter:image)
                  description: doSanitize(selecteEdition.cpagecontent), // (defaults to og:description or twitter:description)
                  title: selecteEdition.cpagename, // (defaults to og:title or twitter:title)
                  message: doSanitize(selecteEdition.cpagecontent), // (only for email sharing)
                  subject: selecteEdition.cpagename, // (only for email sharing)
                  username: "ArsenalNigeriaConverge", // (only for twitter sharing)
                }}
              />
            )}

            <div className="small text-left py-3">
              <i className="bi bi-person-circle"></i> Admin |{" "}
              <i className="bi bi-clock"></i>:{" "}
              {moment(selecteEdition.createdAt).format("Do MMM YYYY h:mma")}
            </div>

            {selecteEdition.cpagephoto !== null && (
              <ImageDisplay
                url={`${selecteEdition.cpagephoto}`}
                title={selecteEdition.cpagename}
                height={isMobile ? 300 : 400}
              />
            )}

            <div
              style={{ overflowX: "auto" }}
              className="py-2 container"
              dangerouslySetInnerHTML={{
                __html: selecteEdition.cpagecontent,
              }}
            />

            <div className="my-3">
              <BannerTop />
            </div>
          </div>
          <div className="col-md-4">
            <div className="h2 text-dark">{id.split("-").join(" ")}</div>
            {edition.map((item, i) => {
              return (
                <div
                  style={{ backgroundColor: "#ffffff" }}
                  key={i}
                  className="my-3 py-2 border-bottom"
                >
                  <Link
                    className="text-decoration-none text-dark"
                    to="#"
                    onClick={() => handleEditionLink(item.cpageid)}
                  >
                    <div className="row">
                      <p className="h6">{item.cpagename}</p>
                    </div>
                  </Link>
                </div>
              );
            })}

            <BannerSide />
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Edition;
