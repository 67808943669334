import React, { useState, useEffect, useContext } from "react";
import { ImageDisplay, VideoDisplay } from "./";
import { Link, useNavigate, useParams } from "react-router-dom";
import { SiteContext } from "../context";

const LatestVideos = ({ id }) => {
  const { apiUrl, isMobile } = useContext(SiteContext);

  const [latestVideos, setLatestVideos] = useState([]);

  useEffect(() => {
    getLatestVideos();
  }, [id]);

  const getLatestVideos = () => {
    fetch(`${apiUrl}/api/allvideos.php?action=fetch_latest_videos&id=${id}`, {
      method: "get",
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.status === 200) {
          setLatestVideos(res.rows);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className="container">
      {latestVideos.length > 0 && (
        <div>
          {" "}
          <div className="h2 text-dark">
            <span style={{ color: "#ff0000" }} className="h1">
              |{" "}
            </span>{" "}
            Latest Videos
          </div>
          {latestVideos.map((item, i) => {
            const opts = {
              height: 100,
              width: "100%",
            };
            return (
              <div key={i} className="bg-white border rounded my-2 py-2">
                <Link
                  className="text-decoration-none text-dark"
                  to={`/video/${item.videntryid}/${item.vidtitle
                    .toLowerCase()
                    .split(" ")
                    .join("-")}/`}
                >
                  <div className="row">
                    {" "}
                    <div className="col-5">
                      <VideoDisplay
                        url={`${item.vidurl}`}
                        title={item.vidtitle}
                        opts={opts}
                      />
                    </div>
                    <div className="col-7 small">{item.vidtitle}</div>
                  </div>
                </Link>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default LatestVideos;
