import React from 'react';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import { OrderForm } from '.';

const validationSchema = Yup.object().shape({
    feedback: Yup.string()
      .label('Feedback')
      .required()
      .min(6, 'Must have at least 6 characters'),
  });
  
const FeedbackForm = ({handleSubmit}) => {

    return (
      <div>
        <div className='container'>

          <Formik
                    initialValues={{
                      feedback:'',
                      rating: ''
                    }}
                    validationSchema={validationSchema}
                    onSubmit={(values, { setSubmitting, resetForm }) => {
                      handleSubmit(values, { setSubmitting, resetForm });
                    }}
                  >
                    {({
                      handleChange,
                      values,
                      handleSubmit,
                      errors,
                      isValid,
                      isSubmitting,
                      handleBlur,
                      touched,
                      setFieldValue
                    }) => (
                      <form onSubmit={handleSubmit}>
                            <div className='form-group my-1'>
                            <label>
                            <Field type="radio" name="rating" value="1" /> Very poor
                            </label>
                            <label>
                            <Field type="radio" name="rating" value="2" /> Poor
                            </label>
                            <label>
                            <Field type="radio" name="rating" value="3" /> Good
                            </label>
                            <label>
                            <Field type="radio" name="rating" value="4" /> Very good
                            </label>
                            <label>
                            <Field type="radio" name="rating" value="5" /> Excellent
                            </label>
                            </div>

                            <div className='form-group my-2'>
                            <Field
                              as="textarea"
                              id="feedback"
                              name="feedback"
                              value={values.feedback}
                              placeholder="Feedback"
                              onBlur={handleBlur('feedback')}
                              autoCapitalize="none"
                              style={{
                                  padding:4
                              }}
                            />
                            {touched.feedback && errors.feedback && <div className='py-1'>
                                  <span className='text-danger'>{touched.feedback && errors.feedback}</span>
                            </div>}


                          </div>
                        <button
                            type='submit'
                            onClick={handleSubmit}
                            title="Submit"
                            disabled={!isValid || isSubmitting}
                              className='btn btn-primary'
                          >
                              Submit
                          </button>
                        
                          </form>
                    )}
                  </Formik>
      </div>
    </div>
    )
}

export default FeedbackForm;