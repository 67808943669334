import { SiteContextProvider } from './context';
import SiteRoutes from './navigation/SiteRoutes';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';

function App() {
  return (
    <SiteContextProvider>
      <SiteRoutes />
    </SiteContextProvider>
  );
}

export default App;
