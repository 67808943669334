import React, { useState, useEffect, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { SiteContext } from "../context";

const BannerSide = () => {
  let navigate = useNavigate();

  const { apiUrl, formatAmount } = useContext(SiteContext);

  const [items, setItems] = useState([]);
  const [msg, setMsg] = useState("");
  const [errorMsg, setErrorMsg] = useState("");

  useEffect(() => {
    getBanners();
  }, []);

  const getBanners = () => {
    fetch(`${apiUrl}/api/bannerads.php?action=getbanner&ty=side`, {
      method: "get",
    })
      .then((res) => res.json())
      .then(async (res) => {
        if (res.status === 200) {
          setItems(res.bannerads);
        } else if (res.status === 400) {
          setErrorMsg(res.msg);
          setTimeout(() => {
            setErrorMsg("");
          }, 5000);
        }
      })
      .catch((error) => {
        console.log("Api call error", error.message);
      });
  };

  return (
    <div className="container rounded shadow bg-white my-3 py-3">
      {items && items.length > 0 && (
        <div style={{ overflow: "hidden" }} className="my-1">
          <div className="text-muted small text-end">ADVERTISEMENT</div>

          {items.map((item) => {
            return (
              <div className="py-2 my-3" key={item.id_ad}>
                <div className="px-1 my-2 text-center">
                  {item.url_ad === "" ? (
                    <img
                      style={{ objectFit: "cover" }}
                      className="img-fluid"
                      src={`${apiUrl}/${item.file_ad}`}
                      height={280}
                      width={`100%`}
                    />
                  ) : (
                    <a href={`${item.url_ad}`} target="_blank">
                      {" "}
                      <img
                        style={{ objectFit: "cover" }}
                        className="img-fluid"
                        src={`${apiUrl}/${item.file_ad}`}
                        height={240}
                        width={`100%`}
                      />
                    </a>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default BannerSide;
