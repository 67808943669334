import React from "react";

export const removeTags = (str) => {
  if (str === null || str === "") {
    return false;
  } else {
    str = str
      .toString()
      .replace(/<p>|<li>|<ul>|<\/ul>| <p class="ql-align-justify">/gi, "")
      .replace(/[\r\n]|<\/p>|<\/li>/gm, " ");

    return str.replace(/(<([^>]+)>)/gi, "");
  }
};

export const getHeading = (text, words) => {
  let headingText = text.toString().split(" ", words);
  let headingTextR = headingText.join(" ");
  let theText;

  if (headingText.length > words - 1) {
    theText = headingTextR + "...";
  } else {
    theText = headingTextR;
  }
  return theText;
};

export const formatViews = (num) => {
  if (num === "" || num === undefined) {
    return num;
  }
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};
