import React, { useState, useContext, useEffect } from 'react';
import { Header, Footer } from './';
import { useNavigate } from 'react-router-dom';
import { Formik, Field } from 'formik';
import { DashboardMenu, NavigationBar } from '../components';
import { SiteContext } from '../context';
import moment from 'moment';
import * as Yup from 'yup';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

const validationSchema = Yup.object().shape({
  arttitle: Yup.string()
    .label('Title')
    .required()
    .min(5, 'Must have at least 5 characters'),
  artcat: Yup.string()
    .label('Category')
    .required(),
  artauthor: Yup.string()
    .label('Author')
    .required(),
});

const ArticleAdd = () => {

  let navigate = useNavigate();

  const { apiUrl } = useContext(SiteContext);

  const userData = JSON.parse(localStorage.getItem('user'));

  const [uploadedPic, setUploadedPic] = useState(null);
  const [categories, setCategories] = useState([]);
  const [story, setStory] = useState('');
  const [pubDate, setPubDate] = useState(new Date());
  const [msg, setMsg] = useState('');
  const [errorMsg, setErrorMsg] = useState('');

  useEffect(() => {
    getCategories();
  }, [])

  const getCategories = () => {
    fetch(`${apiUrl}/api/articlecategories.php?action=fetch&role=${userData.role}`, {
      method: 'get'
    })
      .then((res) => res.json())
      .then(async (res) => {

        if (res.status === 200) {
          setCategories(res.rows);
        } else if (res.status === 400) {
          setErrorMsg(res.msg);
          setTimeout(() => {
            setErrorMsg('');
          }, 5000);
        }
      })
      .catch((error) => {
        console.log('Api call error', error.message);
      });
  }

  const handleSubmit = (values, { setSubmitting, resetForm, setShowModal, selectedPackage, selectedUser }) => {
    if (story === '' || story === undefined) {
      alert('Please provide the content for the article.');
      setSubmitting(false);
      return;
    }

    let formData = new FormData();

    formData.append('artcat', values.artcat);
    formData.append('arttitle', values.arttitle);
    formData.append('arttitleslug', slugify(values.arttitle));
    formData.append('artstory', story);
    formData.append('photo', values.photo);
    formData.append('artpiccaption', values.artpiccaption);
    formData.append('artauthor', values.artauthor);
    formData.append('pubdate', moment(pubDate).format('YYYY-MM-DD'));
    formData.append('artstatus', values.artstatus ? 1 : 0);
    formData.append('artusername', userData.username);
    formData.append('role', userData.role);
    formData.append('action', 'create');

    return fetch(`${apiUrl}/api/articles.php`, {
      method: 'post',
      body: formData
    })
      .then((res) => res.json())
      .then(async (res) => {

        console.log(res);

        if (res.status === 200) {
          resetForm({});
          setSubmitting(false);
          setMsg(res.msg);
          setTimeout(() => {
            setMsg('');
          }, 3000);
          setTimeout(() => {
            navigate('/articles');
          }, 3000);
        } else if (res.status === 400) {
          setErrorMsg(res.msg);
          setTimeout(() => {
            setErrorMsg('');
          }, 5000);
          setSubmitting(false);
        } else {
          alert(
            'Article submission failed',
            'Error connection to the server please check your details and try again',
            [{ text: 'Okay' }]
          );
        }
      })
      .catch((error) => {
        console.log('Api call error', error.message);
      });
  };


  const slugify = (string) => {
    return string
      .toString()
      .trim()
      .toLowerCase()
      .replace(/\s+/g, "-")
      .replace(/[^\w\-]+/g, "")
      .replace(/\-\-+/g, "-")
      .replace(/^-+/, "")
      .replace(/-+$/, "");
  }

  return (
    <div>
      <Header />
      <div>
        <div className='row'>
          <div className='col-md-2 bg-light'>
            <DashboardMenu />
          </div>
          <div className='col-md-10'>
            <div className='container my-2'>

              <NavigationBar section={'Articles'} goback={true} />

              <h3>Article Manage</h3>
              <div className='my-2 d-flex justify-content-between'>
                <div className='align-items-center'>
                  {msg !== '' ? <div className='p-2'><span className='p-2 border border-info text-dark'>{msg}</span></div> : null}
                  {errorMsg !== '' ? <div className='p-2'><span className='p-2 border border-info text-dark'>{errorMsg}</span></div> : null}
                </div>
              </div>

              <Formik
                initialValues={{
                  artid: '',
                  artcat: '',
                  arttitle: '',
                  photo: '',
                  artpiccaption: '',
                  artauthor: '',
                  artstatus: false,
                  artusername: userData.username,
                }}
                validationSchema={validationSchema}
                onSubmit={(values, { setSubmitting, resetForm }) => {
                  handleSubmit(values, { setSubmitting, resetForm });
                }}
              >
                {({
                  handleChange,
                  values,
                  handleSubmit,
                  errors,
                  isValid,
                  isSubmitting,
                  handleBlur,
                  touched,
                  setFieldValue
                }) => (
                  <div>
                    <form onSubmit={handleSubmit} encype="multipart/form-data">
                      <div className='row my-2'>
                        <div className='col-md-8'>

                          <div className='form-group my-1'>
                            <div>
                              <label htmlFor="artcat">Category</label>
                            </div>
                            <Field
                              as="select"
                              id="artcat"
                              name="artcat"
                              value={values.artcat}
                              onChangeText={handleChange('artcat')}
                              placeholder="Category"
                              placeholderTextColor="#999"
                              onBlur={handleBlur('artcat')}
                              autoCapitalize="none"
                              style={{
                                padding: 4
                              }}
                              className="form-control"
                            >
                              <option value="">Select...</option>
                              {categories.length > 0 && categories.map((item, i) => {
                                return <option key={i} value={item.catname}>{item.catname}</option>

                              })}
                            </Field>

                            {touched.artcat && errors.artcat && <div className='py-1'>
                              <span className='text-danger'>{touched.artcat && errors.artcat}</span>
                            </div>}
                          </div>

                          <div className='form-group my-2'>
                            <div>
                              <label htmlFor="arttitle">Title</label>
                            </div>
                            <Field
                              id="arttitle"
                              name="arttitle"
                              value={values.arttitle}
                              onChangeText={handleChange('arttitle')}
                              placeholder="Title"
                              placeholderTextColor="#999"
                              onBlur={handleBlur('arttitle')}
                              autoCapitalize="none"
                              style={{
                                padding: 4,
                              }}
                              className="form-control"
                            />
                            {touched.arttitle && errors.arttitle && <div className='py-1'>
                              <span className='text-danger'>{touched.arttitle && errors.arttitle}</span>
                            </div>}
                          </div>


                          <div className='form-group my-1'>
                            <div>
                              <label htmlFor="artstory">Story</label>
                            </div>
                            <ReactQuill theme="snow" value={story} onChange={setStory} />
                          </div>

                        </div>
                        <div className='col-md-4'>
                          <div className='form-group my-3'>
                            <div>
                              <label htmlFor="photo"> Upload picture</label>
                            </div>
                            <input id="photo" name="photo" type="file" onChange={(event) => {
                              setFieldValue("photo", event.currentTarget.files[0]);
                              setUploadedPic(event.currentTarget.files[0]);
                            }} />
                            {uploadedPic && <img className='my-2' src={URL.createObjectURL(uploadedPic)} class="img-fluid mt-2" width={200} height={100} id="output_image" />}
                          </div>

                          <div className='form-group my-1'>
                            <div>
                              <label htmlFor="artpiccaption">Picture caption</label>
                            </div>
                            <Field
                              id="artpiccaption"
                              name="artpiccaption"
                              value={values.artpiccaption}
                              onChangeText={handleChange('artpiccaption')}
                              placeholder="Picture caption"
                              placeholderTextColor="#999"
                              onBlur={handleBlur('artpiccaption')}
                              autoCapitalize="none"
                              style={{
                                padding: 4
                              }}
                              className="form-control"
                            />
                            {touched.artpiccaption && errors.artpiccaption && <div className='py-1'>
                              <span className='text-danger'>{touched.artpiccaption && errors.artpiccaption}</span>
                            </div>}
                          </div>


                          <div className='form-group my-1'>
                            <div>
                              <label htmlFor="artauthor">Author</label>
                            </div>
                            <Field
                              id="artauthor"
                              name="artauthor"
                              value={values.artauthor}
                              onChangeText={handleChange('artauthor')}
                              placeholder="Author"
                              placeholderTextColor="#999"
                              onBlur={handleBlur('Author')}
                              autoCapitalize="none"
                              style={{
                                padding: 4
                              }}
                              className="form-control"
                            />
                            {touched.artauthor && errors.artauthor && <div className='py-1'>
                              <span className='text-danger'>{touched.artauthor && errors.artauthor}</span>
                            </div>}
                          </div>

                          <div className='form-group my-1'>
                            <div>
                              <label htmlFor="pubdate">Publication date</label>
                            </div>
                            <DatePicker dateFormat={"yyyy-MM-dd"} selected={pubDate} onChange={(date) => {
                              setPubDate(date);
                            }} />
                          </div>

                          <div className='form-group my-1 py-2'>
                            <div>
                              <label htmlFor="artstatus">Status</label>
                              <Field
                                type="checkbox"
                                name="artstatus"
                              /> {values.artstatus ? 'Published' : 'Draft'}
                            </div>
                          </div>

                        </div>
                      </div>
                      <button
                        type='submit'
                        buttonType="outline"
                        onClick={handleSubmit}
                        title="Submit"
                        disabled={!isValid || isSubmitting}
                        loading={isSubmitting}
                        className='btn btn-primary'
                      >
                        Submit
                      </button>


                      <button
                        onClick={() => navigate(-1)}
                        title="Cancel"
                        className='btn btn-secondary mx-2'
                      >
                        Cancel
                      </button>

                    </form>
                  </div>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default ArticleAdd;