import React, { useState } from "react";
import { Formik, Field } from "formik";
import * as Yup from "yup";

const validationSchema = Yup.object().shape({
  username: Yup.string()
    .label("Username")
    .required()
    .min(4, "Must have at least 4 characters"),
  fullname: Yup.string()
    .label("Full name")
    .required()
    .min(5, "Must have at least 5 characters"),
  phone: Yup.string()
    .label("Mobile phone")
    .required()
    .min(11, "Must have at least 11 characters")
    .max(14, "Must have a maximum of 14 characters"),
  email: Yup.string()
    .label("Email")
    .email("Enter a valid email")
    .required("Please enter a registered email"),
  password: Yup.string()
    .label("Password")
    .min(5, "Password must have at least 5 characters "),
  password2: Yup.string().oneOf(
    [Yup.ref("password")],
    "Confirm Password must match Password"
  ),
  agree: Yup.boolean().oneOf([true], "Please check the agreement"),
});

const UpdateSignupForm = ({ handleSubmit, updateItem, setShowModal }) => {
  const [errorMsg, setErrorMsg] = useState("");

  const userData = JSON.parse(localStorage.getItem("user"));

  return (
    <div>
      <div className="container">
        {errorMsg !== "" ? (
          <span className="p-2 my-2 border border-danger text-danger">
            {errorMsg}
          </span>
        ) : null}

        <Formik
          initialValues={{
            id: updateItem.id ? updateItem.id : "",
            username: updateItem.username ? updateItem.username : "",
            fullname: updateItem.name ? updateItem.name : "",
            phone: updateItem.phone ? updateItem.phone : "",
            email: updateItem.email ? updateItem.email : "",
            password: "",
            password2: "",
            userrole: updateItem.role ? updateItem.role : "",
            isverified: updateItem.isverified == 1 ? true : false,
            isactive: updateItem.isactive == 1 ? true : false,
            isavailable: updateItem.isavailable == 1 ? true : false,
            padmin: userData.name,
          }}
          validationSchema={validationSchema}
          onSubmit={(values, { setSubmitting, resetForm }) => {
            handleSubmit(values, { setSubmitting, resetForm, setShowModal });
          }}
        >
          {({
            handleChange,
            values,
            handleSubmit,
            errors,
            isValid,
            isSubmitting,
            handleBlur,
            touched,
            setFieldValue,
          }) => (
            <form onSubmit={handleSubmit} encype="multipart/form-data">
              <div className="row my-2">
                <div className="col-md-6">
                  <div className="form-group my-2">
                    <div>
                      <label htmlFor="fullname">Full name</label>
                    </div>
                    <Field
                      id="fullname"
                      name="fullname"
                      value={values.fullname}
                      placeholder="Full name"
                      onBlur={handleBlur("fullname")}
                      autoCapitalize="none"
                      style={{
                        padding: 4,
                      }}
                      className="form-control"
                    />
                    {touched.fullname && errors.fullname && (
                      <div className="py-1">
                        <span className="text-danger">
                          {touched.fullname && errors.fullname}
                        </span>
                      </div>
                    )}
                  </div>

                  <div className="form-group my-1">
                    <div>
                      <label htmlFor="email">Email</label>
                    </div>
                    <Field
                      id="email"
                      name="email"
                      value={values.email}
                      placeholder="Your email"
                      onBlur={handleBlur("email")}
                      autoCapitalize="none"
                      style={{
                        padding: 4,
                      }}
                      className="form-control"
                    />
                    {touched.email && errors.email && (
                      <div className="py-1">
                        <span className="text-danger">
                          {touched.email && errors.email}
                        </span>
                      </div>
                    )}
                  </div>

                  <div className="form-group my-1">
                    <div>
                      <label htmlFor="userrole">Role</label>
                    </div>
                    <Field
                      as="select"
                      id="userrole"
                      name="userrole"
                      value={values.userrole}
                      onBlur={handleBlur("userrole")}
                      autoCapitalize="none"
                      style={{
                        padding: 4,
                      }}
                      className="form-control"
                    >
                      <option value="">Select...</option>
                      <option value="admin">Admin</option>
                      <option value="columnist">Columnist</option>
                      <option value="member">Member</option>
                    </Field>

                    {touched.userrole && errors.userrole && (
                      <div className="py-1">
                        <span className="text-danger">
                          {touched.userrole && errors.userrole}
                        </span>
                      </div>
                    )}
                  </div>

                  <div className="form-group my-1">
                    <div>
                      <label htmlFor="phone">Phone</label>
                    </div>
                    <Field
                      id="phone"
                      name="phone"
                      value={values.phone}
                      placeholder="Phone"
                      onBlur={handleBlur("phone")}
                      autoCapitalize="none"
                      style={{
                        padding: 4,
                      }}
                      className="form-control"
                    />
                    {touched.phone && errors.phone && (
                      <div className="py-1">
                        <span className="text-danger">
                          {touched.phone && errors.phone}
                        </span>
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group my-1">
                    <div>
                      <label htmlFor="username">Username</label>
                    </div>
                    <Field
                      id="username"
                      name="username"
                      value={values.username}
                      placeholder="Username"
                      onBlur={handleBlur("username")}
                      autoCapitalize="none"
                      style={{
                        padding: 4,
                      }}
                      className="form-control"
                    />

                    {touched.username && errors.username && (
                      <div className="py-1">
                        <span className="text-danger">
                          {touched.username && errors.username}
                        </span>
                      </div>
                    )}
                  </div>

                  <div className="form-group my-1">
                    <div>
                      <label htmlFor="password">Password</label>
                    </div>
                    <Field
                      type="password"
                      id="password"
                      name="password"
                      value={values.password}
                      placeholder="Password"
                      onBlur={handleBlur("password")}
                      style={{
                        padding: 4,
                      }}
                      className="form-control"
                    />

                    {touched.password && errors.password && (
                      <div className="py-1">
                        <span className="text-danger">
                          {touched.password && errors.password}
                        </span>
                      </div>
                    )}
                  </div>

                  <div className="form-group my-1">
                    <div>
                      <label htmlFor="password2">Confirm Password</label>
                    </div>
                    <Field
                      type="password"
                      id="password2"
                      name="password2"
                      value={values.password2}
                      placeholder="Confirm Password"
                      onBlur={handleBlur("password2")}
                      style={{
                        padding: 4,
                      }}
                      className="form-control"
                    />

                    {touched.password2 && errors.password2 && (
                      <div className="py-1">
                        <span className="text-danger">
                          {touched.password2 && errors.password2}
                        </span>
                      </div>
                    )}
                  </div>

                  <div className="form-group my-1">
                    <div>
                      <label htmlFor="agree"> Terms of service?</label>{" "}
                      {updateItem.isavailable ? "Yes" : "No"}
                    </div>
                  </div>

                  <div className="form-group my-1">
                    <div>
                      <label htmlFor="isavailable"> Available?</label>{" "}
                      <Field type="checkbox" name="isavailable" />{" "}
                      {values.isavailable ? "Yes" : "No"}
                    </div>
                  </div>

                  <div className="form-group my-1">
                    <div>
                      <label htmlFor="isverified"> Verified?</label>{" "}
                      {!values.isverified ? (
                        <Field type="checkbox" name="isverified" />
                      ) : (
                        ""
                      )}{" "}
                      {values.isverified ? "Yes" : "No"}
                    </div>
                  </div>

                  <div className="form-group my-1">
                    <div>
                      <label htmlFor="isactive"> Active?</label>{" "}
                      <Field type="checkbox" name="isactive" />{" "}
                      {values.isactive ? "Yes" : "No"}
                    </div>
                  </div>
                </div>
              </div>
              <button
                type="submit"
                buttonType="outline"
                onClick={handleSubmit}
                title="Create Account"
                disabled={!isValid || isSubmitting}
                loading={isSubmitting}
                className="btn btn-primary"
              >
                Submit
              </button>
            </form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default UpdateSignupForm;
