import React, { useState, useEffect, useContext, memo } from "react";
import { Link, useNavigate } from "react-router-dom";
import ReactModal from "react-modal";
import { Dropdown } from "react-bootstrap";
import { Cart, SearchBox, Logo } from "../components";
import { SiteContext } from "../context/index";

const Header = () => {
  const {
    isLoggedOn,
    handleSignOut,
    getOrderItemsTotal,
    breakingNews,
    currentPage,
    handleCurrentPage,
    allCats,
    apiUrl,
    siteconfig,
    isMobile,
    singleProduct,
  } = useContext(SiteContext);

  const userData = JSON.parse(localStorage.getItem("user"));

  const [showModal, setShowModal] = useState(false);
  const [page, setPage] = useState("");
  const [mType, setMType] = useState("");
  const [showBreaking, setShowBreaking] = useState(true);
  const [userClosed, setUserClosed] = useState(false);

  let navigate = useNavigate();

  useEffect(() => {
    if (currentPage !== "") setPage(currentPage);
  }, [currentPage]);

  const doSignOut = () => {
    navigate("/");
    handleSignOut();
  };

  const switchModal = (item) => {
    if (item === "menu") {
      setMType("menu");
    } else if (item === "search") {
      setMType("search");
    } else if (item === "user") {
      setMType("user");
    }
    setShowModal(true);
  };

  const handleUserAction = () => {
    setShowBreaking(false);
    setUserClosed(true);
  };

  const HeaderMenu = () => {
    return (
      <div className="bg-dark">
        <div>
          <Link
            className="p-2 bg-dark btn w-100 rounded-0 text-start border-bottom text-white"
            to="/"
          >
            Home
          </Link>
        </div>
        {allCats.length > 0 &&
          allCats.map((item, i) => {
            return (
              <Link
                key={i}
                className="p-2 bg-dark btn w-100 rounded-0 text-start border-bottom text-white"
                to={`/category/${item.ncatid}/${item.ncat.toLowerCase()}`}
                onClick={() => showModal(false)}
              >
                {item.ncat}
              </Link>
            );
          })}
        <div>
          <Link
            className="p-2 bg-dark btn w-100 rounded-0 text-start border-bottom text-white"
            to="/edition/2023-edition"
          >
            2023 Edition
          </Link>
        </div>
        <div>
          <Link
            className="p-2 bg-dark btn w-100 rounded-0 text-start border-bottom text-white"
            to="/about"
          >
            About Us
          </Link>
        </div>
        <div>
          <Link
            className="p-2 bg-dark btn w-100 rounded-0 text-start border-bottom text-white"
            to="/events"
          >
            Events
          </Link>
        </div>
        <div>
          <Link
            className="p-2 bg-dark btn w-100 rounded-0 text-start border-bottom text-white"
            to="/photos"
          >
            Photo Gallery
          </Link>
        </div>
        <div>
          <Link
            className="p-2 bg-dark btn w-100 rounded-0 text-start border-bottom text-white"
            to="/videos"
          >
            Videos
          </Link>
        </div>
        <div>
          <Link
            className="p-2 bg-dark btn w-100 rounded-0 text-start border-bottom text-white"
            to="/contact"
          >
            Contact
          </Link>
        </div>
      </div>
    );
  };

  const UserMenu = () => {
    return (
      <div>
        {userData ? (
          <h4 className="py-1 border-bottom">Hello {userData.name}</h4>
        ) : (
          <h4 className="py-1 border-bottom">Hello Guest</h4>
        )}
        <div className="py-1 border-bottom">
          <Link className="my-1 text-decoration-none" to="/user">
            My Account
          </Link>
        </div>
      </div>
    );
  };

  return (
    <div>
      <ReactModal
        isOpen={showModal}
        ariaHideApp={false}
        style={{
          overlay: {
            flex: 1,
            justifyContent: "center",
            alignContent: "center",
            position: "fixed",
            top: isMobile ? 0 : "25%",
            bottom: isMobile ? 0 : "25%",
            left: isMobile ? 0 : "20%",
            right: isMobile ? 0 : "20%",
            width: isMobile ? "100%" : "60%",
            height: isMobile ? "100%" : "50&",
            backgroundColor: "rgba(255, 255, 255, 0.75)",
          },
          content: {
            position: "absolute",
            top: "10px",
            left: "10px",
            right: "10px",
            bottom: "10px",
            border: "1px solid #ccc",
            background: "#fff",
            overflow: "auto",
            WebkitOverflowScrolling: "touch",
            borderRadius: "4px",
            outline: "none",
            padding: "10px",
          },
        }}
      >
        <div>
          <div className="d-flex justify-content-end">
            <span
              className="btn btn-dark mb-2 py-1"
              onClick={() => setShowModal(false)}
            >
              <i className="bi bi-x h3 text-white"></i>
            </span>
          </div>
          {mType === "menu" && <HeaderMenu />}
          {mType === "search" && <SearchBox />}{" "}
          {mType === "user" && <UserMenu />}
        </div>
      </ReactModal>

      <div
        style={{
          backgroundColor: "#FF0000",
        }}
      >
        <div className="container">
          <div className="row py-1">
            <div className="col-md-2 col-4">
              <div
                className={
                  isMobile
                    ? "col-6 d-block d-md-none py-2"
                    : "col-md-3 d-none d-md-block py-2"
                }
              >
                <Link to="/" className="text-decoration-none py-2">
                  <Logo />
                </Link>
              </div>
            </div>
            <div className="col-md-8 col-8 d-flex justify-content-end align-items-center">
              <div className="col-md-12 d-md-block d-none">
                <div className="px-1 d-flex justify-content-center">
                  <Link
                    style={{ backgroundColor: "#FF0000" }}
                    className="btn rounded-0 text-start text-white"
                    to="/"
                  >
                    HOME
                  </Link>
                  {allCats.length > 0 &&
                    allCats.map((item, i) => {
                      return (
                        <Link
                          key={i}
                          style={{ backgroundColor: "#FF0000" }}
                          className="px-1 mx-1 btn rounded-0 text-start text-white"
                          to={`/category/${
                            item.ncatid
                          }/${item.ncat.toLowerCase()}`}
                        >
                          {item.ncat.toUpperCase()}
                        </Link>
                      );
                    })}

                  <div className="d-flex justify-content-end align-items-end">
                    <div className="inline py-0 my-0">
                      <Dropdown>
                        <Dropdown.Toggle
                          variant="dark"
                          id="dropdown-basic"
                          style={{ backgroundColor: "#FF0000", border: 0 }}
                        >
                          EDITIONS
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          <Dropdown.Item
                            onClick={() => navigate("/edition/2023-edition")}
                          >
                            2023 (Maiden Edition)
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>

                    <div className="inline py-0 my-0">
                      <Dropdown>
                        <Dropdown.Toggle
                          variant="dark"
                          id="dropdown-basic"
                          style={{ backgroundColor: "#FF0000", border: 0 }}
                        >
                          ABOUT US
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          <Dropdown.Item
                            onClick={() => navigate("/about/team-members")}
                          >
                            Team Members
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={() => navigate("/about/history")}
                          >
                            History
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>

                    <Link
                      style={{ backgroundColor: "#FF0000" }}
                      className="btn rounded-0 text-start px-1 text-white"
                      to="/events"
                    >
                      EVENTS
                    </Link>
                    <div className="inline py-0 my-0">
                      <Dropdown>
                        <Dropdown.Toggle
                          variant="dark"
                          id="dropdown-basic"
                          style={{ backgroundColor: "#FF0000", border: 0 }}
                        >
                          GALLERY
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          <Dropdown.Item onClick={() => navigate("/photos")}>
                            Photos
                          </Dropdown.Item>
                          <Dropdown.Item onClick={() => navigate("/videos")}>
                            Videos
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-12 d-block d-md-none">
                <div className="d-flex justify-content-end align-items-center">
                  <button
                    className="px-2 text-decoration-none btn btn-transparent text-white"
                    onClick={() => switchModal("search")}
                  >
                    <i className="bi bi-search h2"></i>
                  </button>

                  {isLoggedOn ? (
                    <div className="px-1 py-0 my-0">
                      <Dropdown>
                        <Dropdown.Toggle variant="dark" id="dropdown-basic">
                          <span className="h-[25px], w-[25px]">
                            <img
                              src={
                                userData.photo.includes("images/profile")
                                  ? `${apiUrl}/${userData.photo}`
                                  : `${userData.photo}`
                              }
                              height={20}
                              width={20}
                              className="inline-block rounded-circle"
                              alt={userData.name}
                            />{" "}
                          </span>
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          <Dropdown.Item>
                            <h6 className="border-bottom">
                              Hello {userData.name}
                            </h6>
                          </Dropdown.Item>
                          <Dropdown.Item onClick={() => navigate("/user")}>
                            Account
                          </Dropdown.Item>
                          <Dropdown.Item onClick={() => doSignOut()}>
                            <i className="bi bi-box-arrow-right h6"></i> Logout
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  ) : (
                    <div className="px-1 py-0 my-0">
                      <Dropdown>
                        <Dropdown.Toggle variant="dark" id="dropdown-basic">
                          <i className="bi bi-person h4"></i>
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          <Dropdown.Item>
                            <h6 className="border-bottom">Hello Guest</h6>
                          </Dropdown.Item>
                          <Dropdown.Item onClick={() => navigate("/login")}>
                            Login
                          </Dropdown.Item>
                          <Dropdown.Item onClick={() => navigate("/signup")}>
                            Signup
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  )}
                  <span className="px-2">
                    <button
                      className="px-2 text-decoration-none btn btn-dark text-white"
                      onClick={() => switchModal("menu")}
                    >
                      <i className="bi bi-list text-white h4"></i>
                    </button>
                  </span>
                </div>
              </div>
            </div>
            <div className="col-md-2 col-2 d-md-block d-none">
              <div className="d-flex justify-content-end align-items-center">
                <button
                  className="px-2 text-decoration-none btn btn-transparent btn-sm text-white"
                  onClick={() => switchModal("search")}
                >
                  <i className="bi bi-search h3"></i>
                </button>

                {isLoggedOn ? (
                  <div className="px-1 py-0 my-0">
                    <Dropdown>
                      <Dropdown.Toggle variant="dark" id="dropdown-basic">
                        <span className="h-[25px], w-[25px]">
                          <img
                            src={
                              userData.photo.includes("images/profile")
                                ? `${apiUrl}/${userData.photo}`
                                : `${userData.photo}`
                            }
                            height={20}
                            width={20}
                            className="inline-block rounded-circle"
                            alt={userData.name}
                          />{" "}
                        </span>
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item>
                          <h6 className="border-bottom">
                            Hello {userData.name}
                          </h6>
                        </Dropdown.Item>
                        <Dropdown.Item onClick={() => navigate("/user")}>
                          Account
                        </Dropdown.Item>
                        <Dropdown.Item onClick={() => doSignOut()}>
                          <i className="bi bi-box-arrow-right h6"></i> Logout
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                ) : (
                  <div className="px-1 py-0 my-0">
                    <Dropdown>
                      <Dropdown.Toggle variant="dark" id="dropdown-basic">
                        <i className="bi bi-person h4"></i>
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item>
                          <h6 className="border-bottom">Hello Guest</h6>
                        </Dropdown.Item>
                        <Dropdown.Item onClick={() => navigate("/login")}>
                          Login
                        </Dropdown.Item>
                        <Dropdown.Item onClick={() => navigate("/signup")}>
                          Signup
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(Header);
