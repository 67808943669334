import React from 'react';
import { Link } from 'react-router-dom';
import {Header, Footer} from './';

const NothingMatch = () => {
    return (<div>
        <Header />
        <div className='container my-3 py-3'>
        <p className="my-1">
        Page not found!
        </p>
        <Link to='/'>Back to Home</Link>
        </div>
        <Footer />
    </div>
    )
}

export default NothingMatch;