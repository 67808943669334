import React from 'react';
import {Header, Footer} from './';
import { Link } from 'react-router-dom';

const Register = () => {

    return (
        <div>
            <Header />
            <div className='container'>
                
                <h2 className='my-3'>Sign up as...</h2>
                <div className='row p-3'>
                    <div className='col-md-6 bg-light'>
                        <p className='h3 mt-3'><Link to={'/signup'} className='text-decoration-none'>Worker</Link></p>
                    </div>
                    <div className='col-md-6 bg-light'>
                        <p className='h3 mt-3'><Link to='/signup/company' className='text-decoration-none'>Employer</Link></p>
                    </div>
                </div>

            </div>
            <Footer />
        </div>
    )
}

export default Register;