import React, { useState, useEffect, useContext } from "react";
import { Pagination, NavigationBar, BannerSide } from "../components";
import moment from "moment";
import { Link, useNavigate, useLocation, useParams } from "react-router-dom";
import { SiteContext } from "../context";
import { Seo, Header, Footer, BlogCatYear } from "./";
import { Tabs, Tab } from "react-bootstrap";

const BlogsByCategory = () => {
  let { id } = useParams();

  let navigate = useNavigate();

  const { apiUrl, userData, isMobile, siteconfig } = useContext(SiteContext);

  const [articles, setArticles] = useState([]);
  const [total, setTotal] = useState(null);
  const [limit, setLimit] = useState(30);
  const [page, setPage] = useState(1);
  const [cangoBack, setCangoBack] = useState(true);
  const [cangoForward, setCangoForward] = useState(true);
  const [loading, setLoading] = useState(false);
  const [msg, setMsg] = useState("");
  const [errorMsg, setErrorMsg] = useState("");

  useEffect(() => {
    getArticlesByCategory();
  }, [id, page, limit]);

  const getArticlesByCategory = () => {
    setLoading(true);

    fetch(
      `${apiUrl}/api/articles.php?action=fetchblogcategory&category=${id}&page=${page}&limit=${limit}`,
      {
        method: "get",
      }
    )
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        if (res.status === 200) {
          if (res.rows.length > 0) {
            setArticles(res.rows);
            setTotal(res.rows.length);
            setLoading(false);
          }
        } else if (res.status === 400) {
          setLoading(false);
          setErrorMsg(res.msg);
          setTimeout(() => {
            setErrorMsg("");
          }, 3000);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const doPrevious = async () => {
    if (parseInt(page) > 1) {
      await setPage(() => parseInt(page - 1));
      setCangoBack(true);
      setCangoForward(true);
    } else {
      setCangoBack(false);
      setCangoForward(true);
    }
  };

  const doNext = async () => {
    if (parseInt(page * limit) < total) {
      await setPage(() => parseInt(page + 1));
      setCangoForward(true);
      setCangoBack(true);
    } else {
      setCangoForward(false);
      setCangoBack(true);
    }
  };

  const handleChange = (event) => {
    let newLimit = event.target.value;
    setLimit(newLimit);
  };

  return (
    <div>
      <Header />
      <Seo
        title={`Blog | ${siteconfig.shopname}`}
        description="Articles"
        url={`${apiUrl}/blog`}
        img={`${apiUrl}/${siteconfig.shoplogo}`}
      />
      <div className="container my-2">
        <NavigationBar
          section={"Blog"}
          page={`${id.charAt(0).toUpperCase()}${id
            .slice(1)
            .replace(/-/gi, " ")}`}
          goback={true}
        />

        <div className="my-2 d-flex justify-content-between">
          <div className="align-items-center">
            {msg !== "" ? (
              <div className="p-2">
                <span className="p-2 text-dark">{msg}</span>
              </div>
            ) : null}
            {errorMsg !== "" ? (
              <div className="p-2">
                <span className="p-2 text-dark">{errorMsg}</span>
              </div>
            ) : null}
          </div>
        </div>

        {articles.length > 0 && (
          <div className="row">
            <div className="col-md-9 col-lg-9">
              <div className="row">
                {articles.map((article, i) => {
                  return (
                    <div className="col-md-6 col-lg-4 p-2">
                      <Link
                        className="text-decoration-none text-dark text-left"
                        to={`/post/${article.titleslug}`}
                      >
                        <div className="my-1 p-3 rounded">
                          <h5 className="my-2 py-2">{article.title}</h5>
                          <span className="small text-left">
                            {" "}
                            <i className="bi bi-person-circle"></i>
                            {article.author} | <i className="bi bi-clock"></i>:{" "}
                            {moment(article.createdAt).format(
                              "Do MMM YYYY h:mma"
                            )}
                          </span>
                        </div>
                      </Link>
                    </div>
                  );
                })}
              </div>

              {articles.length > 0 && (
                <Pagination
                  limit={limit}
                  page={page}
                  total={total}
                  cangoBack={cangoBack}
                  cangoForward={cangoForward}
                  handleChange={handleChange}
                  doNext={doNext}
                  doPrevious={doPrevious}
                />
              )}
            </div>
            <div className="col-md-3 col-lg-3">
              <BlogCatYear />
              <BannerSide />
            </div>
          </div>
        )}
        {loading && (
          <div className="d-flex justify-content-center align-content-center my-5">
            <div class="spinner-border text-secondary" role="status" />
          </div>
        )}
      </div>
      <Footer />
    </div>
  );
};

export default BlogsByCategory;
