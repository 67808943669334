import React, { useContext } from 'react';
import { useNavigate, Link } from 'react-router-dom';

const NavigationBar = ({ section, page, goback }) => {
    let navigate = useNavigate();

    const doGoBack = () => {
        navigate(-1);
    }

    const removeDash = (d) => {
        let text = d.toString().replace(/\-+/g, ' ');
        return text;
    }

    return (
        <div className='mt-3 mx-2 p-2 bg-light'>
            <Link className='text-decoration-none' to='/'>Home </Link> / {goback ? <button className='bg-light border-0 text-primary text-decoration-none' onClick={() => doGoBack()}>{section}</button> : <span className='text-dark'>{section}</span>} {page ? <span> / <span style={{ 'text-transform': 'capitalize' }}>{page.toString().replace(/\-+/g, ' ')}</span></span> : ''}
        </div>
    )
}

export default NavigationBar;