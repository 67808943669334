import React, { useContext } from "react";
import { SiteContext } from "../context";

const ImageDisplay = ({
  url,
  title,
  height,
  showText,
  largeimg,
  category,
  moreNews,
}) => {
  const { apiUrl, isMobile } = useContext(SiteContext);

  const showWebp = (url) => {
    let fullImgUrl = `${apiUrl}/${url}`;

    return `<picture>
  <source type="image/webp" srcset="${fullImgUrl}">
  <img src="${fullImgUrl}" alt="image description">
</picture>`;
  };

  const getUrl = (url) => {
    if (isMobile && !url.includes("webp")) {
      let urlSplit = url.split("/");

      return `${apiUrl}/${urlSplit[0]}/${urlSplit[1]}/${urlSplit[2]}/${urlSplit[3]}/thumbnail/${urlSplit[4]}`;
    } else {
      return `${apiUrl}/${url}`;
    }
  };

  if (url.includes("webp")) {
    showWebp(url);
  }

  if (showText && !largeimg) {
    return (
      <div style={{ position: "relative", height: { height } }}>
        <img
          style={{
            objectFit: "cover",
            backgroundColor: "#000000",
          }}
          src={url.includes("images/news") ? getUrl(url) : url}
          alt={title}
          height={height}
          width={`100%`}
        />
        <div
          style={{
            position: "absolute",
            width: "100%",
            backgroundColor: "#000000",
            opacity: 0.7,
            bottom: 0,
            padding: 10,
            color: "#ffffff",
          }}
        >
          <p style={{ textShadow: "inherit" }} className={"h6"}>
            {title}
          </p>
        </div>
      </div>
    );
  } else if (moreNews) {
    return (
      <div style={{ height: { height } }}>
        <img
          style={{
            objectFit: "cover",
            backgroundColor: "#000000",
            opacity: 1.0,
          }}
          src={url.includes("images/news") ? getUrl(url) : url}
          alt={title}
          height={height}
          width={"100%"}
        />
      </div>
    );
  } else if (largeimg) {
    return (
      <div style={{ position: "relative", height: { height } }}>
        <img
          style={{
            objectFit: "cover",
            backgroundColor: "#000000",
          }}
          src={url.includes("images/news") ? getUrl(url) : url}
          alt={title}
          height={height}
          width={`100%`}
        />
        <div
          style={{
            position: "absolute",
            width: "100%",
            backgroundColor: "#000000",
            opacity: 0.7,
            bottom: 0,
            padding: 20,
            color: "#ffffff",
          }}
        >
          <p style={{ textShadow: "inherit" }} className={"h6"}>
            {title}
          </p>
          <div>
            <span className="text-danger"> | </span>
            <span className="small">{category.toUpperCase()}</span>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div style={{ height: { height } }}>
        <img
          style={{
            objectFit: "cover",
            backgroundColor: "#000000",
            opacity: 1.0,
          }}
          src={url.includes("images/news") ? getUrl(url) : url}
          alt={title}
          height={height}
          width={`100%`}
        />
      </div>
    );
  }
};

export default ImageDisplay;
