import React, {useState, useEffect, useContext} from 'react';
import {Header, Footer} from './';
import { Link, useParams, useLocation } from 'react-router-dom';
import { SiteContext } from '../context';

const Activation = () => {

    let {rkey, email} = useParams();

    const {apiUrl} = useContext(SiteContext);

    const [applicant, setApplicant] = useState({});
    const [msg, setMsg] = useState('');
    const [errorMsg, setErrorMsg] = useState('');

    useEffect(() => {
        if(msg === '' && errorMsg === '') {
            verifyEmail();
        }
    },[])

    const verifyEmail = () => {
            fetch(`${apiUrl}/api/users.php?action=activate&rkey=${rkey}&email=${email}`, {
                method:'get'
              }).then((res) => {
                  return res.json()
              }).then((res) => {
                if(res.status === 200) {
                    setMsg(res.msg);
                    setApplicant(res.user);
                  } else if (res.status === 400) {
                      setErrorMsg(res.msg)
                  }
              }).catch((err) => {
                  console.log(err)
              })    
    }

    return (
        <div>
            <Header />
            <div className='container'>
            <h2 className='my-3'>Application Step 2</h2>
                {msg != '' && <div className='my-3'>
                    <h5>{msg} - {email}</h5>
                    <p className='h6 mt-3'><Link to={'/signup-step2'} state={{email, rkey}} className='text-decoration-none'>Continue</Link></p>
                </div>}

                {errorMsg != '' && <div className='my-3'>
                    <h5>{errorMsg} - {email}</h5>
                </div>}

            </div>
            <Footer />
        </div>
    )
}

export default Activation;