import React, { useState, useContext } from "react";
import { Link } from "react-router-dom";
import ReactModal from "react-modal";
import { Logo } from "../components";
import { SiteContext } from "../context";
import moment from "moment";

const Footer = () => {
  const { siteconfig, apiUrl, categories, handleCurrentPage } =
    useContext(SiteContext);

  const [itemSelected, setItemSelected] = useState({});
  const [showModal, setShowModal] = useState(false);

  const handleShow = (name, url) => {
    let slaccount = {
      name,
      url,
    };
    setItemSelected(slaccount);
    setShowModal(true);
  };
  return (
    <div>
      <ReactModal
        isOpen={showModal}
        ariaHideApp={false}
        style={{
          overlay: {
            flex: 1,
            justifyContent: "center",
            alignContent: "center",
            position: "fixed",
            top: "65%",
            bottom: "10%",
            left: "5%",
            right: "5%",
            width: "90%",
            height: "25%",
            backgroundColor: "rgba(255, 255, 255, 0.75)",
          },
          content: {
            position: "absolute",
            top: "10px",
            left: "10px",
            right: "10px",
            bottom: "10px",
            border: "1px solid #ccc",
            background: "#fff",
            overflow: "auto",
            WebkitOverflowScrolling: "touch",
            borderRadius: "4px",
            outline: "none",
            padding: "20px",
          },
        }}
      >
        <div className="row">
          <div className="col-10">
            <p className="h3 my-1 py-1">{itemSelected.name}</p>
            <a
              href={itemSelected.url}
              target="_blank"
              className="my-1 py-2 text-decoration-none"
            >
              {itemSelected.url}
            </a>
          </div>
          <div className="col-2">
            <span
              className="btn btn-secondary"
              onClick={() => setShowModal(false)}
            >
              x
            </span>
          </div>
        </div>
      </ReactModal>
      <div className="text-dark" style={{ backgroundColor: "#eee" }}>
        <div className="container">
          <div className="row p-2">
            <div className="col-md-5 my-1 d-flex justify-content-center align-content-center">
              <div className="w-100">
                <Logo />

                <p className="my-2">{siteconfig.shopdescription}</p>
              </div>
            </div>
            <div className="col-md-2 my-1">
              <p className="h5">Links</p>
              <div
                style={{ width: 30 }}
                className="border-bottom border-3 border-danger"
              />
              <div className="my-2">
                <p>
                  <Link
                    className="text-dark text-decoration-none py-1"
                    onClick={() => handleCurrentPage("editions")}
                    to="/edition/2023-edition"
                  >
                    Editions
                  </Link>
                </p>
                <p>
                  <Link
                    className="text-dark text-decoration-none py-1"
                    onClick={() => handleCurrentPage("events")}
                    to="/events"
                  >
                    Events
                  </Link>
                </p>
                <p>
                  <Link
                    className="text-dark text-decoration-none py-1"
                    onClick={() => handleCurrentPage("")}
                    to="/page/advertise"
                  >
                    Advertise with us
                  </Link>
                </p>

                <p>
                  <Link
                    className="text-dark text-decoration-none py-1"
                    onClick={() => handleCurrentPage("")}
                    to="/about"
                  >
                    About Us
                  </Link>
                </p>
              </div>
            </div>

            <div className="col-md-2 my-1">
              <p className="h5">Support</p>
              <div
                style={{ width: 30 }}
                className="border-bottom border-3 border-danger"
              />

              <div className="my-2">
                <p>
                  <Link
                    className="text-dark text-decoration-none py-2"
                    onClick={() => handleCurrentPage("contact")}
                    to="/contact"
                  >
                    Contact Us
                  </Link>
                </p>
              </div>
              {(siteconfig.slfacebook !== "" ||
                siteconfig.sltwitter !== "" ||
                siteconfig.slinstagram !== "" ||
                siteconfig.slyoutube !== "") && (
                <p className="h5 mt-5 my-1">Follow us on</p>
              )}
              {siteconfig.slfacebook != "" && (
                <span className="p-1 my-1">
                  <a
                    className="py-1 rounded-circle h4"
                    href={siteconfig.slfacebook}
                    target="_blank"
                  >
                    <i className="bi bi-facebook"></i>
                  </a>
                </span>
              )}
              {siteconfig.sltwitter != "" && (
                <span className="p-1 my-1">
                  <a
                    className="py-1 rounded-circle h4"
                    href={siteconfig.sltwitter}
                    target="_blank"
                  >
                    <i className="bi bi-twitter"></i>
                  </a>
                </span>
              )}
              {siteconfig.slinstagram != "" && (
                <span className="p-1 my-1">
                  <a
                    className="py-1 rounded-circle h4"
                    href={siteconfig.slinstagram}
                    target="_blank"
                  >
                    <i className="bi bi-instagram"></i>
                  </a>
                </span>
              )}
              {siteconfig.slyoutube != "" && (
                <span className="p-1 my-1">
                  <a
                    className="py-1 rounded-circle h4"
                    href={siteconfig.slyoutube}
                    target="_blank"
                  >
                    <i className="bi bi-youtube"></i>
                  </a>
                </span>
              )}
            </div>

            <div className="col-md-3 my-1">
              <p className="h5">Contact</p>
              <div
                style={{ width: 30 }}
                className="border-bottom border-3 border-danger"
              />
              <div className="my-2">
                <span className="p-1">{siteconfig.shopaddress}</span>
                <br /> <br />
                <span className="p-1">
                  <i className="bi bi-phone"></i>: {siteconfig.shopphone}
                </span>
                <br />
                <br />
                <span className="p-1">
                  <i className="bi bi-envelope"></i>:{" "}
                  {siteconfig.shopemailaddress}
                </span>
                <br />
                <span className="p-1">
                  <i className="bi bi-envelope"></i>:{" "}
                  loc@arsenalnigeriaconverge.com
                </span>{" "}
              </div>
            </div>
          </div>
        </div>
        <div
          style={{ backgroundColor: "#FF0000" }}
          className="py-1 text-white small"
        >
          <div className="container d-flex justify-content-between">
            <p className="py-1 font-weight-bold">
              Copyright{" "}
              <Link className="text-white text-decoration-none" to="/">
                {siteconfig.shopname}
              </Link>{" "}
              {moment().format("YYYY")} |{" "}
              <Link
                className="text-white text-decoration-none"
                to="/page/privacy-policy"
              >
                Privacy Policy
              </Link>{" "}
            </p>
            <p className="py-1 font-weight-bold">
              Developed by{" "}
              <a
                className="text-white text-decoration-none"
                href="https://justwebservices.com"
                target={"_blank"}
              >
                Just Web Services
              </a>{" "}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
