import React, { useState, useEffect, useContext } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import {
  UpdateProfilePictureAdmin,
  UpdateProfileIdentificationAdmin,
  DashboardMenu,
  NavigationBar,
} from "../components";
import { UpdateUserModal } from "../modals";
import { Header, Footer } from "./";
import { SiteContext } from "../context";

const UserProfileDisplay = () => {
  const { apiUrl } = useContext(SiteContext);

  const userData = JSON.parse(localStorage.getItem("user"));

  const [user, setUser] = useState({});
  const [msg, setMsg] = useState("");
  const [updateItem, setUpdateItem] = useState({});
  const [errorMsg, setErrorMsg] = useState("");
  const [showUpdateModal, setShowUpdateModal] = useState(false);

  let location = useLocation();

  let id = location.state;

  useEffect(() => {
    getUser();
  }, [id]);

  const getUser = () => {
    fetch(
      `${apiUrl}/api/users.php?user=${id}&action=fetchsingle&role=${userData.role}`,
      {
        method: "get",
      }
    )
      .then((response) => response.json())
      .then(async (res) => {
        if (res.status === 200) {
          await setUser(res.user);
        } else if (res.status === 400) {
          setMsg(res.msg);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleSubmit = (values, { setSubmitting, resetForm, setShowModal }) => {
    let formData = new FormData();

    formData.append("name", values.fullname);
    formData.append("email", values.email);
    formData.append("username", values.username);
    formData.append("phone", values.phone);
    formData.append("photo", values.photo);
    formData.append("role", values.userrole);
    formData.append("user", values.id);
    formData.append("action", "update");

    return fetch(`${apiUrl}/api/users.php`, {
      method: "post",
      body: formData,
    })
      .then((res) => res.json())
      .then(async (res) => {
        if (res.status === 200) {
          resetForm({});
          setMsg(res.msg);
          setShowModal(false);
          setSubmitting(false);
          setTimeout(() => {
            setMsg("");
          }, 5000);
        } else if (res.status === 400) {
          setErrorMsg(res.msg);
          setTimeout(() => {
            setErrorMsg("");
          }, 5000);
          setSubmitting(false);
        } else {
          alert(
            "Update failed",
            "Error connection to the server please check your details and try again",
            [{ text: "Okay" }]
          );
        }
      })
      .catch((error) => {
        console.log("Api call error", error.message);
      });
  };

  const handleUpdate = (user) => {
    setUpdateItem(user);
    setShowUpdateModal(true);
  };

  return (
    <div>
      <UpdateUserModal
        user={userData}
        updateItem={updateItem}
        handleSubmit={handleSubmit}
        showModal={showUpdateModal}
        setShowModal={setShowUpdateModal}
        errorMsg={errorMsg}
      />

      <Header />
      <div>
        <div className="row">
          <div className="col-md-2 bg-light">
            <DashboardMenu />
          </div>
          <div className="col-md-10">
            <div className="container my-2">
              <NavigationBar section={"Users"} goback={true} />

              <h3 className="my-2">
                {user.name}'s Profile (
                <button onClick={() => handleUpdate(user)}>Update</button>)
              </h3>

              <div className="container p-3">
                <div className="my-1">
                  <div className="p-2">
                    <img
                      className="img-fluid rounded"
                      src={`${apiUrl}/${user.photo}`}
                      height={200}
                      width={200}
                      alt={user.name}
                    />
                  </div>
                </div>
                <div className="row my-1">
                  <div className="col-md-5 p-2">Name</div>
                  <div className="col-md-7 h6 p-2">{user.name}</div>
                </div>
                <div className="row my-1">
                  <div className="col-md-5 p-2">Username</div>
                  <div className="col-md-7 h6 p-2">{user.username}</div>
                </div>
                <div className="row my-1">
                  <div className="col-md-5 p-2">Phone</div>
                  <div className="col-md-7 h6 p-2">{user.phone}</div>
                </div>
                <div className="row my-1">
                  <div className="col-md-5 p-2">Email</div>
                  <div className="col-md-7 h6 p-2">{user.email}</div>
                </div>
                <div className="row my-1">
                  <div className="col-md-5 p-2">User role</div>
                  <div className="col-md-7 h6 p-2">{user.role}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default UserProfileDisplay;
