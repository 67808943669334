import React, { useContext, useState, useEffect } from 'react';
import {Header, Footer} from './';
import { Link } from 'react-router-dom';
import { SiteContext } from "../context/index";
import DashboardMenu from '../components/DashboardMenu';

const UserDashboard = () => {
    const { apiUrl,  msg  } = useContext(SiteContext);

    const userData = JSON.parse(localStorage.getItem('user'));

    const [totalWorkers, setTotalWorkers] = useState('');
    const [totalEmployers, setTotalEmployers] = useState('');

    useEffect(() => {
        getTotalStats();
    },[])

    
const getTotalStats = () => {
    fetch(`${apiUrl}/api/stats.php?action=fetch`, {
      method:'get'
    }).then((res) => res.json()
    ).then((res) => {
        if(res.status === 200) {
            setTotalWorkers(res.workers);
            setTotalEmployers(res.employers);
        }
    }).catch((err) => {
        console.log(err)
    })
}


    return (
        <div>
            <Header />
            <div>
                <div className='row'>
                    <div className='col-md-2 bg-light'>
                        <DashboardMenu />
                    </div>
                    <div className='col-md-10'>
                        <h2 className='my-2 p-3'>Welcome {userData.username} to your {userData.role} dashboard</h2>
                       
                       {msg != '' ? <span className='my-2'>{msg}</span> : null}
                       
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default UserDashboard;